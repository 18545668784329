import { Directive } from 'vue';

const specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', '-', 'Delete', 'ArrowLeft', 'ArrowRight'];
const regex: RegExp = new RegExp('^[a-zA-Z0-9 ]{0,30}$');

const directive: Directive = {


    beforeMount(el, binding, vnode, prevVnode)  {

    
        el.addEventListener('input', (event: any) => {
            el.value = el.value.replace(/[^a-zA-Z0-9\s]/g, '');    // get a alphabet-numeric string
            if (el.value && el.value.length > 30) {
                el.value= el.value.substr(0, 30);
                // event.preventDefault();
              }
        })


        el.addEventListener('paste', (event: any) => {
            event.preventDefault();
            const pastedInput: string = event.clipboardData
                .getData('text/plain')
                .replace(/[^a-zA-Z0-9\s]/g, '');    // get a alphabet-numeric string
            document.execCommand('insertText', false, pastedInput);
        })

        el.addEventListener('drop', (event: any) => {
            event.preventDefault();
            const pastedInput: string = event.clipboardData
                .getData('text/plain')
                .replace(/[^a-zA-Z0-9\s]/g, ''); // get a alphabet-numeric string
            document.execCommand('insertText', false, pastedInput);
        })


    }

};


export default directive;