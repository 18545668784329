
// import Vue from 'vue';
import  { createApp } from 'vue';
import App from './App.vue'
import Cookies from 'js-cookie';
import router from './router';
import './registerServiceWorker';
import { VueFinalModal } from 'vue-final-modal';
import { createVfm } from 'vue-final-modal'
import appAlphaNumeric from "@/common/directives/appAlphaNumeric";
import 'vue-final-modal/style.css'
import "./variable.css"
// import Vuelidate from 'vuelidate';
// Vue.config.productionTip = false;

// import { Component } from 'vue-property-decorator';
// Component.registerHooks(['validations']);

// Vue.cookies.config('1d');

// import VModal from 'vue-js-modal';

// Vue.use(VModal, { dynamic: true, injectModalsContainer: true });


if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
}

const app = createApp(App);
app.config.globalProperties.$cookies = Cookies;
app.directive('appAlphaNumeric', appAlphaNumeric);
app.component('VueFinalModal', VueFinalModal);
 // Create the Vue app instance
// app.use(Vuelidate);
const vfm = createVfm()
app.use(router); // Use the router instance
app.use(vfm).mount('#app')


// createApp(App).use(router).mount('#app');
