/* tslint:disable */

import Vue from 'vue';
import environment from '@/environment'
import Cookies from 'js-cookie';
// Vue.use(VueCookies);

const AppCookieService = {

    $cookie: Cookies,

    checkCookie(name: string): boolean | undefined {
        try {
            return  Cookies.get(name) != undefined;
        } catch (e) { console.log(e); }
    },

    getCookie(name: string) {
        try {
            return  Cookies.get(name);
        } catch (e) { console.log(e); }
    },

    getAllCookies(): {} {
        try {
            return  Object.keys(Cookies.get());
        } catch (e) { 
            return {};
        }
    },

    setCookie(name: string, value: string, expires?: any,
        path?: string, domain?: string, secure?: boolean) {
        try {
            if(process.env.NODE_ENV == 'production' || environment.qaEnviornment){
                domain = `${environment.domain}`;    //uncomment this line when working policybazaar.com domain
            }
            if (!path) {
                path = '/';
            }
            let expiryDate = new Date();
            if (typeof expires == 'number') {
                expiryDate.setDate(expiryDate.getDate() + expires);
                expires = expiryDate;
            }
            else if (!(expires instanceof Date)) {
                expiryDate.setTime(expiryDate.getTime() + (expires.hours * 60 * 60 + expires.minutes * 60) * 1000);
                expires = expiryDate;
            }
            //path, domain, secure
            Cookies.set(name, value,{path:path,domain:domain, secure:secure,expires:expires});
        } catch (e) { console.log(e); }
    },

    deleteCookie(name: string, path?: string, domain?: string): void {
        try {
            domain = `${environment.domain}`;    // uncomment this line when working policybazaar.com domain
            if (!path) {
                path = '/';
            }
            Cookies.set(name, '',  {path:path,domain:domain ,expires:new Date('Thu, 01 Jan 1970 00:00:01 GMT')});
        } catch (e) { console.log(e); }
    },
};

export default AppCookieService;
