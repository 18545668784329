<template>
  <div id="app">
    <div class="prequotes" v-bind:class="{'prequoteNew':!prequote}">
      <section v-if="loading && !forHealth" class="formbox-outer">
        <div class="formbox-inner">
          <div class="loader">
            <div class="w--spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          </div>
        </div>
      </section>
      <router-view v-else></router-view>
    </div>
  </div>
</template>
<script>
import { defineComponent,ref,watch } from 'vue';
import HeaderComponent from "@/components/app-header.vue";
import InsHeaderComponent from "@/components/app-ins-header.vue";
import SjbHeaderComponent from "@/components/app-sjb-header.vue";
import FooterComponent from "@/components/app-footer.vue";
import globalService from "@/services/globalService";
import WomenFooterComponent from "@/components/app-footer-women.vue";
import SpouseFooterComponent from "@/components/app-footer-spouse.vue";
import SjbFooterComponent from "@/components/app-footer-sjb.vue";
import EngineerFooterComponent from "@/components/app-footer-engineer.vue";
import AppCookieService from "@/services/cookieService";
import isDarkMode from "@/utils/darkModeDetector"
import router from "./router";
export default defineComponent({
  components: { HeaderComponent,InsHeaderComponent, FooterComponent, SjbHeaderComponent, WomenFooterComponent, EngineerFooterComponent,SpouseFooterComponent,SjbFooterComponent },
    setup() {
    // Define reactive variables
    const loading = ref(true);
    const prequote = ref(false);
    const forsjb = ref(false);
    const forbmiCalc = ref(false);
    const forposp = ref(false);
    const forSpouse = ref(false);
    const forHealth = ref(false);
    const forWomen = ref(false);
    const forEngineer = ref(false);
    const isMobileDevice = globalService.isMobileDevice();
    
    // Use watcher to observe route changes
    const handleRouteChange = (to) => {
    
      if(to.name === '/'){
          prequote.value = true;
      }else if (to.name === 'health') {
        forHealth.value = true;
      } else if (to.name === 'sjbprequotes') {
        forsjb.value = true;
      } else if (to.name === 'bmicalculator') {
        forbmiCalc.value = true;
      } else if (to.name === 'pospprequotes') {
        forposp.value = true;
      } else if (to.name === 'spousePrequote') {
        forSpouse.value = true;
      } else if (to.name === 'specialday') {
        forWomen.value = true;
      } else if (to.name === 'engineers') {
        forEngineer.value = true;
      }
      if((isDarkMode() && !AppCookieService.checkCookie('_cst_')) || AppCookieService.getCookie('_cst_')=='true'){
        // ((darkModeMediaQuery.matches && !this.cookieService.checkCookie(environment.custSelectedtheme))  || this.cookieService.getCookie(environment.custSelectedtheme) == 'true') && document.body.classList.add('dark-theme');
        document.body.classList.add('dark-theme');
      }
      loading.value = false;
        console.log(to.name,prequote.value)
    };

    // Watch router changes
    watch(
      () => router.currentRoute.value,
      (to, from) => {
        handleRouteChange(to);
      }
    );
    
    return {
      loading,
      forsjb,
      forbmiCalc,
      forposp,
      forSpouse,
      forHealth,
      forWomen,
      forEngineer,
      isMobileDevice
    };
  },
});
</script>
<style>


body {
  font-size: 13px;
  background: #fbfbfd;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}
* {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
ul,
li {
  list-style: none;
}
input,
select {
  color: #252733;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  letter-spacing: 0px !important;
}
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  outline: 0;
}
input:required,
select:required {
  box-shadow: none;
}
::-webkit-input-placeholder {
  color: #d0cdcd;
  font-weight: 500;
}
::-moz-placeholder {
  color: #d0cdcd;
  font-weight: 500;
}
:-ms-input-placeholder {
  color: #d0cdcd;
  font-weight: 500;
}
:-moz-placeholder {
  color: #d0cdcd;
  font-weight: 500;
}
i,
em {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #252733;
  -webkit-box-shadow: 0 0 0px 900px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.v--modal-overlay {
  background: #000000d4 !important;
}
.w--spinner {
  width: 70px;
  text-align: center;
  margin: 0 auto;
}

.w--spinner.narrow {
  width: 48px;
}

.v--modal-box.modal-open {overflow-y:auto !important;}


.w--spinner > div {
  width: 12px;
  height: 12px;
  margin-left: 3px;
  background-color: #314451;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.w--spinner.white > div {
  background: #fff;
}

.w--spinner.orange > div {
  background: #fc5846;
}

.w--spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.w--spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  100%,
  80% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.loader {
  top: 200px;
  left: 0;
  position: relative;
  text-align: center;
}

.loader img {
  max-width: 40%;
  margin-top: 100px;
}

.prequotes .formbox-outer {
  width: 1080px;
  margin: 0 auto;
}

.prequotes .formbox-outer.women { width:100%;}

.formbox-inner {
  width: 100%;
  float: left;
  position: relative;
  height: 560px;
  background: transparent;
  /* box-shadow: 0 1px 30px rgba(0, 0, 0, 0.3); */
  /* border-radius: 4px; */
  margin-top: 55px;
}

@media (min-width: 320px) and (max-width: 1024px) {

  .prequotes .formbox-outer {
    width: 100%;
    padding: 0;
  }

  body {background:#fff;}

  
   .prequotes .header-mob {
    display: block;
    padding: 6px 20px;
    width: 100%;
    padding: 6px 20px;
    float: left;
    background: var(--juranypointbg);
    color: #fff;
    
  }
  .prequotes .logo img {
    width: 34px;
  }
  .prequotes .quotetop-rights {
    text-align: right;
  }
.v--modal-box.countrypopup {width:100%  !important; left:0 !important;}

.v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: 100% !important;
    left: 0 !important;
}
  
}


/* comman scroll bar */
/* For webkit-based browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width:auto /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
    background-color: var(--gray_f1); /* Set the background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background-color: var( --scroll_bar_thumb); /* Set the color of the scrollbar thumb */
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll_bar_thumb_hover); /* Set the color of the scrollbar thumb */
}


::-moz-scrollbar-track {
    background-color: var(--gray_f1); /* Set the background color of the scrollbar track */
}

::-moz-scrollbar-thumb {
    background-color: var( --scroll_bar_thumb); /* Set the color of the scrollbar thumb */
}
::-moz-scrollbar-thumb:hover {
  background-color: var(--scroll_bar_thumb_hover); /* Set the color of the scrollbar thumb */
}

input:-webkit-autofill, input:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--themecolor);
    -webkit-box-shadow: 0 0 0 900px var(--white) inset;
    
    transition: background-color 5000s ease-in-out 0s;
    caret-color: var(--themecolor);
}
.prequoteNew{
    background: var(--bgWColor2);
        overflow: hidden;
}

</style>
