import { Directive } from 'vue';

const directive: Directive = {
    beforeMount(el, binding, vnode, prevVnode) {

        window.addEventListener('scroll', () => {
            const scrollValue: number = window.scrollY;
            const action: string = scrollValue >= binding.value ? 'add' : 'remove';
            el && el.classList[action]("fixed");
        }, {passive: true});
    }
};

export default directive;