/* tslint:disable */

const globalService = {
    Android(): boolean {
        return navigator.userAgent.match(/Android/i) !== null;
    },

    BlackBerry(): boolean {
        return navigator.userAgent.match(/BlackBerry/i) !== null;
    },

    iOS(): boolean {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
    },

    Opera(): boolean {
        return navigator.userAgent.match(/Opera Mini/i) !== null;
    },

    Windows(): boolean {
        return navigator.userAgent.match(/IEMobile/i) !== null;
    },

    isMobileDevice(): boolean {
        return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
    }
}

export default globalService;
