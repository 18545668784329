/* tslint:disable */
import { environment as defaultEnvironment } from './environment.default';
const config = {
    ...defaultEnvironment,
    production: false,
    qaEnviornment: true,
    //baseUrl: 'http://termlocal.policybazaar.com:8081/',

    // baseUrl: 'http://local.policybazaar.com:8080/',
    // baseUrl: 'https://termnewqa.policybazaar.com/',
    // baseUrl: 'https://termcjapi.policybazaar.com/',
    baseUrl: 'https://termnewqa.policybazaar.com/',
    cookie_progressiveQuestion: '_pq_',
    cookie_quotePageTypeId: '_qpti_',
    cookie_payOptionType: '_pot_',
    cookie_enquiry: 'enq_t',
    cookie_visitId: 'Cookie_VisitLog',
    cookie_otAgentId: 'AgentId',
    cookie_visitorToken : "visitorToken",
    cookie_referralCookie:'VisitProfileCookie',
    domain: 'policybazaar.com',
    cookie_spouseAdded: '_sa_',
    cookie_childAdded: '_ca_',
    cookie_sortFilter: '_sf_',
    cookie_CustEligibility: '_ce_',
    cookie_AgentId: '_ai_',
    cookie_InternalIp: '_ip_',
    cookie_custCity: '_cc_',
    cookie_talkToExpert: '_tte_',
    cookie_clickToCallText: '_ctct',
    limited_default_cover: 65,
    wholelife_default_cover: 99,
    max_policy_term_to_sort_coverage: 75,
    max_term_to_sort_coverage: 40,
    callPreChat: true,
    default_sumassured_group_plan: 2000000,
    max_sumassured_group_plan: 3500000,
    minPolicyTerm: 10,
    maxPolicyCoverage: 99,
    minTotalPayout: 1000000,
    maxTotalPayout: 200000000,
    minMonthlyIncome: 30000,
    maxMonthlyIncome: 2000000,
    event_logger_url: 'https://termnewqa.policybazaar.com/eventmanagement/api/event',
    item_plus_planId: 818,
    clicktocall: 'cc',
    notificationTimeout: 2000,
    trop_30yrs_default_coverupto: 60,
    trop_30_40yrs_default_coverupto: 70,
    trop_40yrs_default_coverupto: 80,
    spousePlanIdSortOrder: [776, 787, 749, 911, 912, 818, 789],
    childPlanSortOrder: [756, 752, 753],
    item_planIds: [787, 789],
    dhfl_supplierId: 25,
    ipruTropPlanId: 949,
    staticAegonObjectPlanId: 787,
    wholeLifemaxvalue: 99,
    cookie_newLead: '_nl_',
    isMonthly: '_ism_',
    bhartiAxaSupplier: 20,
    WorkingHoursStartTime: '10:00:00',
    WorkingHoursEndTime: '20:30:00',
    tropDisplayOnLumpsumPlan: [10],
    tatTrophySupplierIds: [17, 117, 1117],
    claimSettledTrophySupplierIds: [2, 22, 222],
    sloganSupplierIds: [17, 117, 1117],
    highlightedRiders: ['Express Refund', 'Insta Claims'],
    quotesPageUrl: 'https://termqa.policybazaar.com/quotes/',
    preQuotesPageUrl: 'https://termqa.policybazaar.com/',
    preloadAppUrl: 'https://termlife.policybazaar.com/',
    newBaseUrl: 'https://termqaapi.policybazaar.com/termquote/',
    offerIds:[217,218,219,220,221],
    preApprovedCustIdP:"_pre_custid_p",
    preApprovedCustIdE:"_pre_custid_e",
    pasaRedirection:'https://ipru.policybazaar.com/iProtectMobile?PaymentFreqency=12&enquiryId=%enq%&planId=892&tabName=oneTimePlans&payOptionType=Regular&isMobileDefault=true&isMobileDefault=true&pasa=true',
    maxRedirection: 'https://maxlife.policybazaar.com/MaxSSP?PaymentFreqency=12&enquiryId=%enq%&planId=9908&tabName=oneTimePlans&ppt=26&payOptionType=Regular&isDiscountAvailable=true&isMobileDefault=true'
}

export default config;