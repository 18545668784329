/* tslint:disable */

import development from './environment';
import production from './environment.production';


let config: any = {
    'development': development,
    'production': production
};

const environment = config[process.env.NODE_ENV];
export default environment;


