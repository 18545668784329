import { Directive } from 'vue';

const directive: Directive = {
    beforeMount(el, binding, vnode, prevVnode) {

        let toggle: boolean = false;
        el.addEventListener('click', () => {
            toggle = !toggle;
            const action = toggle ? 'add' : 'remove';
            let ulElem = (<HTMLInputElement>document.getElementById(binding.value.id));
            if (ulElem) {
                el.classList[action]("iconChange");
                ulElem.classList[action]("show");
                ulElem.classList[action]("animate");
            }

        }, false);
    }
};

export default directive;