/* tslint:disable */


const staticGaData: any = { productId: '7', lobSection1: 'Term Insurance', lobSection2: 'Customer Journey' };

const analyticsService = {
    _currentSet: {},
    dimensions(someDimensions: any, byPass: boolean = true) {
        this._currentSet = someDimensions;
    },


    trigger() {
        (<any>window).dataLayer.push(this._currentSet);
    },

    setGtmDataWithLebel(event: string, page: string, responsedata: any, eventLabel:any, byPass?: boolean) {
        const gtmdata = Object.assign({ event: event, pageName: page, 
            'eventLabel': eventLabel ? eventLabel.toString() : 'NA',
            'eventCategory': event,
            'eventAction': page, }, responsedata, { lobSection3: page })
        this.pageViewWithCustomDimensions(gtmdata, byPass);
    },

    setGtmdata(event: string, page: string, responsedata: any, byPass?: boolean) {
       try {
        const gtmdata = Object.assign({ event: event, pageName: page }, responsedata, { lobSection3: page })
        this.pageViewWithCustomDimensions(gtmdata, byPass);
       } catch (error) {}
    },

    updateCustomDimensions(someDimensions: any, byPass?: boolean) {
        Object.keys(someDimensions).forEach((key:any) => {
            if (key=="mobile" || key=="mobileNumber" || key=="mobileNo") {
              delete someDimensions[key];
            }
          });
        this.dimensions(someDimensions, byPass);
    },

    pageView(overridePath?: string) {
        try {
            this.trigger();
        }
        catch (e) {
            console.log(e);
        }
    },

    pageViewWithCustomDimensions(someDimensions: any, byPass?: boolean) {
        this.updateCustomDimensions({ ...someDimensions, ...staticGaData }, byPass);
        this.pageView();
    },

    oldGtmTagfire(leadId: number, custId: number, isNew: boolean, pageName: string) {
        let eventName = "";
        if (isNew) {
            eventName = "Lead";
        } else {
            eventName = "LeadUpdate";
        }

        try {
            if (leadId && typeof (leadId) !== undefined) {
                const lead = leadId ? leadId.toString() : "";
                const data = { LeadID: lead };
                this.setGtmdata('termLead', pageName, data, true);
            }
        } catch (ex) {
        }
    },

}

export default analyticsService;